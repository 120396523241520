<template>
  <div v-if="dimension" class="step__body">
    <h3>{{ step.title ? step.title : step.name }}</h3>
    <p>{{ step.description }}</p>
    <div class="row option">
      <DimensionOption :option="step.width" :option-dim="dimension.width" type="width"/>
      <DimensionOption :option="step.height" :option-dim="dimension.height" :height-max="heightMax" type="height"/>
      <DimensionAlternate :dimension="step" />
    </div>
    <div class="row">
      <div class="col-md-12">
        <br>
        <div class="alert alert-info" v-if="dimensionCapsText">{{ dimensionCapsText }}</div>
        <div class="badge text-danger" v-if="name === 'Schiebetore'">Ausleger: {{ ausleger }}cm
        </div><tooltip :tooltip="tooltip" style="display:inline-block" v-if="name === 'Schiebetore'"><span class="fa fa-info-circle"></span></tooltip>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import DimensionOption from './dimensionOption'
import DimensionAlternate from './dimensionAlternate'
export default {
  name: 'Dimension',
  components: {
    DimensionOption,
    DimensionAlternate
  },
  setup () {
    const tooltip = ref({
      text: 'Der Ausleger dient als Gegengewicht für das Schiebetor und ermöglicht damit die freitragende Konstruktion. Der Ausleger ist das hintere Dreieck bei dem Schiebetor, dessen Länge sich nach der Torbreite variiert.<br/> Als Beispiel: Ein Schiebetor hat eine Einfahrtsbreite von 3,5m, der Ausleger hat bei der Torbreite 1,5m, das gesamte Schiebetor ist somit 5m breit. D.h. das Schiebetor braucht 5m Platz um komplett rauszufahren. Es ist wichtig diese Angaben bei der Planung zu berücksichtigen.<br/> Den Ausleger kann man als Sonderkonstruktion 20cm kürzer machen, einfach Anfragen.',
      image: 'https://www.loewe-zaun.de/wp-content/uploads/2024/04/ausleger.jpg',
      type: 'blind'
    })

    const heightMax = ref(0)
    const store = useStore()
    const step = computed(() => {
      return store.getters['configurator/currentStep']
    })
    const dimensionCapsText = computed(() => {
      return store.getters['configurator/dimensionCapsText']
    })
    const name = computed(() => {
      return store.getters['configurator/name']
    })
    const ausleger = computed(() => {
      return store.getters['configurator/ausleger']
    })
    const dimension = computed(() => {
      const steps = store.getters['configurator/steps']
      const options = steps.find(s => s.model_step).options
      const option = options.find(o => o.selected)
      const arr = option.special_dim.map(s => {
        if ((s.width.from <= Number.parseInt(step.value.width.value) && s.width.to >= Number.parseInt(step.value.width.value)) && s.price > 0) {
          return Math.max(s.height.to)
        }
      })
      arr.filter(a => {
        if (typeof a !== 'undefined') {
          heightMax.value = a
        }
      })
      return option.dimension
    })
    return {
      step,
      dimension,
      heightMax,
      tooltip,
      dimensionCapsText,
      name,
      ausleger
    }
  }
}
</script>
