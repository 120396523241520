<template>
  <div v-if="step" class="step__body">
    <h3>{{ step.name }}</h3>
    <p v-if="step.description">{{ step.description }}</p>
    <div class="row">
      <div class="col-sm-12">
        <div class="text-center">
          <a href="#!" class="btn btn-primary btn-lg" @click="addToCart">zum Warenkorb</a>
        </div>
        <br>
        <div class="text-center">
          <a href="#!" class="btn btn-outline-primary" @click="showRequestForm">Unverbindliche Anfrage</a>
          <a href="#!" class="btn btn-outline-primary" @click="printed()">Konfiguration ausdrucken</a>
          <a href="https://www.loewe-zaun.de/prospekte" class="btn btn-outline-primary" target="_blank">Prospekt anfordern</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { Base64 } from 'js-base64'
import axios from 'axios'
export default {
  setup () {
    const store = useStore()
    const router = useRouter()
    const step = computed(() => {
      return store.getters['configurator/currentStep']
    })
    function addToCart () {
      store.dispatch('checkout/addItem', store.getters['configurator/summary'])
      router.push({ name: 'cart' })
    }
    async function printed () {
      const apiKey = btoa(process.env.VUE_APP_API_KEY)
      const apiUrl = process.env.VUE_APP_API_URL
      const summary = JSON.stringify(store.getters['configurator/summary'])
      const p = document.getElementById('print-content')
      const config = Base64.encode(summary)
      await axios.post(apiUrl + 'print/configurator', { re: apiKey, config: config }).then(resp => {
        if (resp.data) {
          p.innerHTML = resp.data
          setTimeout(() => {
            window.print()
          }, 2000)
        }
      })
    }
    function showRequestForm () {
      store.dispatch('configurator/showRequestForm')
    }
    return {
      step,
      addToCart,
      printed,
      showRequestForm
    }
  }
}
</script>
