<template>
  <component :is="currentStepType" :key="currentStep.id" />
  <div class="text-center mb-2 mt-2">
    <button class="btn btm-sm btn-outline-primary" @click="nextStep" id="btn-scroll" :data-id="currentStep.id">zum nächsten Schritt <i class="fas fa-arrow-right"></i></button>
  </div>
</template>
<script>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
import { defineAsyncComponent } from '@vue/runtime-core'
// Load layout components dynamically.
const requireContext = require.context('~/pages/configurator/steps', false, /.*\.vue$/, 'sync')
const componentNames = requireContext.keys().map((file) => file.replace(/(^.\/)|(\.vue$)/g, ''))
const components = {}
componentNames.forEach((component) => { // component represents the component name
  components[component] = defineAsyncComponent({
    loader: () => import('@/pages/configurator/steps/' + component + '.vue'),
    delay: 0,
    suspensible: false
  })
})
export default {
  name: 'Options',
  components,
  setup () {
    const store = useStore()
    const currentStepType = computed(() => {
      const currentStep = store.getters['configurator/currentStep']
      return currentStep.type
    })
    const currentStep = computed(() => {
      return store.getters['configurator/currentStep']
    })
    function nextStep () {
      store.commit('configurator/SET_NEXT_STEP')
    }
    return {
      currentStep,
      currentStepType,
      nextStep
    }
  }
}
</script>
