<template>
  <div v-if="step" class="step__body">
    <h3>{{ step.name }}</h3>
    <p v-if="step.description">{{ step.description }}</p>
    <div class="row">
      <div class="col-sm-3">
        <div class="card pointer">
          <img class="card-img-top" :src="$filters.image(step.image, '190x')">
          <div class="card-body">
            <div class="card-block text-center px-1">
              <p class="card-title">{{ step.name }}</p>
            </div>
            <div class="text-center description" v-html="option.description" v-if="option && option.description"></div>
          </div>
          <div class="card-footer">
            <div class="input-group mb-3">
            <button class="btn btn-primary" @click="sub" type="button"><i class="fas fa-minus"></i></button>
            <input type="text" :value="step.value + ' ' + unit" class="form-control" disabled>
            <button class="btn btn-primary" @click="add"><i class="fas fa-plus"></i></button>
          </div>
          </div>
      </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
export default {
  props: {
    option: {
      type: Object
    },
    type: null
  },
  setup (props) {
    const store = useStore()
    const step = computed(() => {
      return store.getters['configurator/currentStep']
    })
    const unit = computed(() => {
      return 'Stück'
    })
    function add () {
      const value = Math.min(step.value.value + 1, step.value.max)
      setQuantity(value)
    }
    function sub () {
      const value = Math.max(step.value.value - 1, step.value.min)
      setQuantity(value)
    }
    function setQuantity (value) {
      store.dispatch('configurator/setQuantity', { value })
    }
    return {
      step,
      unit,
      add,
      sub
    }
  }
}
</script>
